import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";

function DetalleFactura(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('cartera_view') && permisos!='-1'){
    window.location = '/home';
  }
  const [totalFactura, setTotalFactura] = useState([]);
  const [totalAbonos, setTotalAbonos] = useState([]);
  const [totalRestante, setTotalRestante] = useState([]);
  const [numeroDocumento, setNumeroDocumento] = useState([]);
  const [nombreCliente, setNombreCliente] = useState([]);
  const [razonSocial, setRazonSocial] = useState([]);
  const [estadoFactura, setEstadoFactura] = useState([]);
  const [pagos, setPagos] = useState([]);
  const [totalRestanteSinFormato, setTotalRestanteSinFormato] = useState([]);
  const [fechaCreacion, setFechaCreacion] = useState("");
  const id = useParams();

  const consultarDetalleFactura = () => {
    let payload = {
      id_registro: id.id
    }
    axiosClient.post('/detalle-documento', payload)
    .then(({data}) => {
      setPagos(data[0].pagos)
      setNumeroDocumento(data[0].numero_documento)
      setNombreCliente(data[0].nombre)
      setTotalFactura(data[0].total_formato)
      setTotalAbonos(data[0].abonos)
      setTotalRestante(data[0].restante)
      setRazonSocial(data[0].razon_social)
      setEstadoFactura(data[0].estado)
      setTotalRestanteSinFormato(data[0].restante_sinformato)
      setFechaCreacion(data[0]?.fecha_creacion)
    })
  }

  const enviarEstadoTabla = (id_registro) => {
    let payload = {
      tabla: 'documentos_pagos',
      id_registro,
      estado: '4'
    }
    Swal.fire({ 
      text: "Confirmar eliminación de registro", 
      icon: "info", 
      showCancelButton: !0, 
      confirmButtonText: "Enviar", 
      confirmButtonColor: '#008E8A', 
      cancelButtonText: "Cancelar", 
      cancelButtonColor: '#d70021' 
    }).then((e) => {
      if(e.isConfirmed){
        axiosClient.post("/enviar-estado-tabla", payload)
          .then(({ data_ }) => {
            window.location = '/cartera-detalle-factura/'+id.id;
        })
      }
    });
  }

  const archivarCartera = () => {
      let payload = {
        tabla: 'documentos',
        id_registro: id.id,
        estado: '2'
      }
      
      Swal.fire({ 
        text: "Confirmar archivar registro", 
        icon: "info", 
        showCancelButton: !0, 
        confirmButtonText: "Enviar", 
        confirmButtonColor: '#86E736', 
        cancelButtonText: "Cancelar"
      }).then((e) => {
        if(e.isConfirmed){
          axiosClient.post("/enviar-estado-tabla", payload)
            .then(({ data_ }) => {
              window.location = '/cartera-pendiente';
          })
        }
      });
  }

  useEffect(() => {
    consultarDetalleFactura();
  }, [])
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Pagos {numeroDocumento}</h1>
            {permisos.includes('cartera_add') || permisos=="-1" ?
            <div className="top-right-button-container">
              {estadoFactura!='2' ?
                <a href={"/cartera-nuevo-pago/"+id.id}>
                  <button type="button" className="btn btn-primary mb-1">
                    Agregar Pago
                  </button>
                </a> : ''}
              &nbsp;&nbsp;<a className="activatePointer" onClick={() => archivarCartera()}>
                <button type="button" className="btn btn-info mb-1">
                  Archivar
                </button>
              </a>
            </div>
            : ''}
            <p className="list-item-heading">{razonSocial ? razonSocial+' - ' : ''}{nombreCliente}</p>
            <p>Fecha creación: {fechaCreacion}</p>
            <div className="separator" />
          </div>
        </div>
        <div className="row">
          <div className="btn btn-primary rounded col-lg-12">
            <h5>
              FACTURA: <strong>{totalFactura}</strong>&nbsp;&nbsp;&nbsp;&nbsp; ABONOS:&nbsp;
              <strong>{totalAbonos}</strong>&nbsp;&nbsp;&nbsp;&nbsp; SALDO:&nbsp;
              <strong>{totalRestante}</strong>
            </h5>
          </div>
          <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
          <br></br><br></br>
            <table
              id="datatableRow"
              className="data-table responsive nowrap cont_tabla"
              data-order='[[ 1, "desc" ]]'
            >
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Forma de pago</th>
                  <th>Valor</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {pagos.map((registro, i) => (
                  <tr>
                    <td>
                      <p className="list-item-heading">{registro.fecha_pago}</p>
                    </td>
                    <td>
                      <p className="list-item-heading">
                        {registro.tipo_pago}
                      </p>
                    </td>
                    <td>
                      <p className="list-item-heading">{registro.valor_formato}</p>
                    </td>
                    {estadoFactura!='2' ? 
                    <td>
                      <button
                        id="btnGroupDrop1"
                        type="button"
                        className="btn btn-secondary dropdown-toggle"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        Accion
                      </button>
                      <div
                        className="dropdown-menu"
                        aria-labelledby="btnGroupDrop1"
                      >
                        <a className="dropdown-item activatePointer" onClick={() => enviarEstadoTabla(registro.id)}>Eliminar</a>
                      </div>
                    </td> : <td><div className="badge badge-warning">ARCHIVADO</div></td> }
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
    </main>
  );
}

export default DetalleFactura;
