import React, { useEffect,useState,createRef } from "react";
import axiosClient from "../../axios-client";
import { useForm } from "react-hook-form";

function ControlProduccion(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('produccion_view') && permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [productos, setProductos] = useState([]);
  const [notas, setNotas] = useState([]);

  const notaRef = createRef();

  const verNotas = (id_registro) => {
    let payload = {
      id_registro
    }
    localStorage.setItem('id_pedido_item', id_registro);
    axiosClient.post('/notas-pedido-item', payload)
    .then(({data})=> {
      setNotas(data)
    })
  }

  const onSubmitNota = (data) => {
    let payload = {
      id_user: localStorage.getItem("ID_USER"),
      id_pedido_item: localStorage.getItem("id_pedido_item"),
      nota: notaRef.current.value
    }
    axiosClient.post("/agregar-nota-pedido-item", payload)
      .then(({ data_ }) => {
        window.location = "/produccion-control";
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  }

  const consultarPedidos = () => {
    let payload = {
      estados: '1,2,3'
    }
    axiosClient.post('/obtener-item-pedido', payload)
    .then(({data})=> {
      setProductos(data)
    })
  }

  const enviarEstadoItemPedido = (id_registro, estado) => {
    let payload = {
      id_registro,
      estado,
      id_user: localStorage.getItem("ID_USER")
    }
    axiosClient.post('/actualizar-estado-item-pedido', payload)
    .then(({data})=>{
      consultarPedidos();
    })
  }

  useEffect(() => {
    consultarPedidos();
  }, [])

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Control de Producción</h1>
            <div className="separator" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
          <br></br><br></br>
            <table
              id="datatableRow"
              className="data-table responsive nowrap cont_tabla_export"
              data-order='[[ 1, "desc" ]]'
            >
              <thead>
                <tr>
                  <th>Pedido</th>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th>Estado</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {productos.map((registro, i) => (
                <tr>
                  <td>
                    <p className="list-item-heading">{registro.id_pedidos}</p>
                  </td>
                  <td>
                    <p className="list-item-heading">
                      {registro.codigo}
                      <br />
                      <span className="text-muted">
                        {registro.titulo}
                      </span>
                      <br />
                      <span className="text-muted">
                        {registro.cliente}
                      </span>
                    </p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.cantidad}</p>
                  </td>
                  <td>
                    <button
                      type="button"
                      className={registro.clase}
                      data-toggle="modal"
                      data-target="#exampleModal"
                      onClick={() => verNotas(registro.id)}
                    >
                      {registro.estado}
                    </button>
                  </td>
                  <td>
                    <button
                      id="btnGroupDrop1"
                      type="button"
                      className="btn btn-secondary dropdown-toggle"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Accion
                    </button>
                    <div
                      className="dropdown-menu"
                      aria-labelledby="btnGroupDrop1"
                    >
                      <a className="dropdown-item activatePointer" onClick={() => enviarEstadoItemPedido(registro.id, '2')}>
                        Preparar
                      </a>
                      <a className="dropdown-item activatePointer" onClick={() => enviarEstadoItemPedido(registro.id, '3')}>
                        Enviar a Planta
                      </a>
                    </div>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Notas:
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body">
                <h5 className="card-title" />
                <div>
                {notas.map((value, key) => (
                  <div key={key} className="d-flex flex-row mb-3 border-bottom justify-content-between">
                    <a>
                        <img
                           src={value.users.image}
                          alt={value.users.name}
                          className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                        />
                      </a>
                      <div className="pl-3 flex-grow-1">
                        <p className="list-item-heading mb-0 ">
                          {value.users.name} &nbsp;
                          <span className="text-muted mb-0 text-small">
                            {value.fecha}
                            <i className="simple-icon-clock mr-1" />
                            {value.hora}
                          </span>{" "}
                        </p>
                        <p className="mt-3">
                          <i className="simple-icon-bubble mr-1 heading-icon" />
                          {value.nota}
                        </p>
                      </div>
                  </div>
                  ))}
                  <form onSubmit={handleSubmit(onSubmitNota)}>
                    <div className="comment-contaiener">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Agregar Nota"
                          ref={notaRef}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-secondary" type="submit">
                            <span className="d-inline-block">Guardar</span>
                            <i className="simple-icon-arrow-right ml-2" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  
                </div>
              </div>
            </div>
            <div className="modal-footer"></div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default ControlProduccion;
