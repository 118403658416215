import React, { createRef, useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import $ from 'jquery';

function TodosPedidos(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('pedidos_view') && permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [pedidos, setPedidos] = useState([]);
  const [notas, setNotas] = useState([]);

  const notaRef = createRef();

  const consultarPedidos = () => {
    let payload = {
      estado: '1,3'
    };
    axiosClient.post('/consultar-pedidos', payload)
    .then(({data})=> {
      setPedidos(data);
    })
  }

  const verNotas = (id_registro) => {
    let payload = {
      id_registro
    }
    localStorage.setItem('id_pedido', id_registro);
    axiosClient.post('/notas-pedido', payload)
    .then(({data})=> {
      setNotas(data)
    })
  }

  const onSubmitNota = (data) => {
    let payload = {
      id_user: localStorage.getItem("ID_USER"),
      id_pedido: localStorage.getItem("id_pedido"),
      nota: notaRef.current.value
    }
    axiosClient.post("/agregar-nota-pedido", payload)
      .then(({ data_ }) => {
        window.location = '/pedidos-todos'
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  }

  const irAgregar = () => {
    window.location = "/pedidos-nuevo";
  };

  const enviarEstadoTabla = (id_registro) => {
    let payload = {
      id_pedido: id_registro
  }
  Swal.fire({ 
      text: "Confirmar archivar el pedido y los despachos", 
      icon: "info", 
      showCancelButton: !0, 
      confirmButtonText: "Enviar", 
      confirmButtonColor: '#86E736', 
      cancelButtonText: "Cancelar",
    }).then((e) => {
      if(e.isConfirmed){
        axiosClient.post('/archivar-pedido', payload)
        .then(({data})=> {
          window.location = '/pedidos-todos';
        })
      }
    });
  }

  const verDetallePedido = (id_pedido) => {
    window.location = '/detalle-pedido/'+id_pedido
  }

  const eliminarRegistro = (id_registro) => {
    Swal.fire({ 
      text: "Confirmar eliminación de registro", 
      icon: "info", 
      showCancelButton: !0, 
      confirmButtonText: "Enviar", 
      confirmButtonColor: '#86E736', 
      cancelButtonText: "Cancelar",
    }).then((e) => {
      if(e.isConfirmed){
        let payload = {
          id_registro,
          estado: '5',
          tabla: 'pedidos'
        }
        axiosClient.post("/enviar-estado-tabla", payload)
          .then(({ data }) => {
          window.location = "/pedidos-todos";
        })
        .catch((err) => {
          const response = err.response;
          console.log(response);
        });
      }
    });
  };

  useEffect(() => {
    consultarPedidos();
  }, []);
  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Todos los Pedidos</h1>
            {permisos.includes('pedidos_add') || permisos=='-1' ?
            <div className="top-right-button-container">
              <div className="btn-group">
                <button
                  className="btn btn-primary"
                  type="button"
                  onClick={irAgregar}
                >
                  AGREGAR
                </button>
              </div>
            </div>
            : ''}
            <div className="separator mb-5" />
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-12 mb-4">
            <div className="card">
              <div className="card-body">
                <table className="data-table cont_tabla responsive">
                  <thead>
                    <tr>
                      <th>Pedido</th>
                      <th>Fecha</th>
                      <th>Vendedor</th>
                      <th>Cliente</th>
                      <th>Valor</th>
                      <th>Estado</th>
                      <th>Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pedidos.map((registro, i) => (
                    <tr key={i}>
                      <td onClick={() => verDetallePedido(registro.id)} className="activatePointer">{registro.id}</td>
                      <td>{registro.fecha}</td>
                      <td>
                        <strong>{registro.nombreVendedor}</strong>
                      </td>
                      <td>
                        {registro.razon_social ? registro.razon_social+' - ' : ''}{registro.nombreCliente}
                      </td>
                      <td>{registro.total}</td>
                      <td>
                        <button
                          type="button"
                          className={registro.clase}
                          data-toggle="modal"
                          data-target="#exampleModal"
                          onClick={() => verNotas(registro.id)}
                        >
                          {registro.estado}
                        </button>                      
                      </td>
                      <td>
                        <button
                          id="btnGroupDrop1"
                          type="button"
                          className="btn btn-secondary dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Accion
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="btnGroupDrop1"
                        >
                          {registro.estado!='Finalizado' && permisos=='-1' ? <a className="dropdown-item activatePointer" href={"/editar-pedido/"+registro.id}>
                            Editar</a> : ''}
                            {registro.estado=='Finalizado' ?
                          <a className="dropdown-item activatePointer" href={"/detalle-pedido-finalizado/"+registro.id}>
                            Detalle</a> : ''}
                            {permisos.includes('pedidos_edit') || permisos=='-1' ?
                          <a className="dropdown-item activatePointer" onClick={() => enviarEstadoTabla(registro.id)}>
                            Archivar
                          </a>
                          : ''}
                          {permisos.includes('pedidos_delete') || permisos=='-1' ?
                          <a className="dropdown-item activatePointer" onClick={() => eliminarRegistro(registro.id)}>
                            Eliminar
                          </a>
                          : ''}
                        </div>
                      </td>
                    </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Notas:
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body">
                <h5 className="card-title" />
                <div>
                {notas.map((value, key) => (
                  <div key={key} className="d-flex flex-row mb-3 border-bottom justify-content-between">
                    <a>
                        <img
                           src={value.users.image}
                          alt={value.users.name}
                          className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                        />
                      </a>
                      <div className="pl-3 flex-grow-1">
                        <p className="list-item-heading mb-0 ">
                          {value.users.name} &nbsp;
                          <span className="text-muted mb-0 text-small">
                            {value.fecha}
                            <i className="simple-icon-clock mr-1" />
                            {value.hora}
                          </span>{" "}
                        </p>
                        <p className="mt-3">
                          <i className="simple-icon-bubble mr-1 heading-icon" />
                          {value.nota}
                        </p>
                      </div>
                  </div>
                  ))}
                  <form onSubmit={handleSubmit(onSubmitNota)}>
                    <div className="comment-contaiener">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control notas"
                          placeholder="Agregar Nota"
                          ref={notaRef}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-secondary" type="submit">
                            <span className="d-inline-block">Guardar</span>
                            <i className="simple-icon-arrow-right ml-2" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default TodosPedidos;
