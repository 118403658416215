import React, { useEffect, useState } from "react";
import axiosClient from "../../axios-client";
import Swal from "sweetalert2";

function HistorialCartera(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('cartera_view') && permisos!='-1'){
    window.location = '/home';
  }

  const [cartera, setCartera] = useState([]);

  const consultarCartera = () => {
    let payload = {
      estado: '2,4'
    }
    axiosClient.post('/obtener-cartera', payload)
    .then(({data})=> {
      setCartera(data)
    })
  }

  const verEstadoFactura = (id) => {
    window.location = '/cartera-detalle-factura/'+id;
  }

  const enviarEstadoTabla = (id_registro) => {
    let payload = {
      tabla: 'documentos',
      id_registro,
      estado: '1'
    }
    Swal.fire({ 
      text: "Confirmar acción de desarchivar", 
      icon: "info", 
      showCancelButton: !0, 
      confirmButtonText: "Enviar", 
      confirmButtonColor: '#86E736', 
      cancelButtonText: "Cancelar"
    }).then((e) => {
      if(e.isConfirmed){
        axiosClient.post("/enviar-estado-tabla", payload)
          .then(({ data_ }) => {
            window.location = '/cartera-historial';
        })
      }
    });
    
  }

  useEffect(() => {
    consultarCartera()
  }, [])

  return (
    <main>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1>Historial de cartera</h1>
            <div className="separator" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
          <br></br><br></br>
            <table
              id="datatableRos"
              className="data-table responsive nowrap cont_tabla_export"
              data-order='[[ 1, "desc" ]]'
            >
              <thead>
                <tr>
                  <th>Documento</th>
                  <th>Cliente</th>
                  <th>Valor</th>
                  <th>Estado</th>
                  <th>Fecha Limite</th>
                  <th>Estado</th>
                  <th>Acción</th>
                </tr>
              </thead>
              <tbody>
                {cartera.map((registro, i) => (
                  <tr key={i}>
                    <td onClick={() => verEstadoFactura(registro.id)} className="activatePointer">
                      <p className="list-item-heading">{registro.numero_documento}</p>
                    </td>
                    <td>
                      <p className="list-item-heading">
                        {registro.nombre}
                      </p>
                    </td>
                    <td>
                      <p className="list-item-heading">{registro.total_formato}</p>
                    </td>
                    <td>
                      <span className="badge badge-pill badge-outline-success mb-1">
                        {registro.abonos}
                      </span>
                      <br />
                      <span className="badge badge-pill badge-outline-danger mb-1">
                        {registro.restante}
                      </span>
                    </td>
                    <td>
                      <p className="text-muted">{registro.fecha_vencimiento}</p>
                      <span className={registro.class_restantes}>
                        {registro.dias_restantes}
                      </span>
                    </td>
                    <td>
                      {registro.estado=='4' ? <div className="badge badge-danger">ANULADO</div> : <div className="badge badge-warning">ARCHIVADO</div>}
                    </td>
                    <td>
                        <button
                          id="btnGroupDrop1"
                          type="button"
                          className="btn btn-secondary dropdown-toggle"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Accion
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="btnGroupDrop1"
                        >
                          <a className="dropdown-item activatePointer" onClick={() => enviarEstadoTabla(registro.id)}>
                            PENDIETE POR DESARCHIVAR
                          </a>
                        </div>
                      </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  );
}

export default HistorialCartera;
