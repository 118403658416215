import React, { useEffect, useState, createRef } from 'react'
import { useParams } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useForm } from 'react-hook-form';

function Despachados(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('despachos_view') && permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [notas, setNotas] = useState([]);

  const notaRef = createRef();

  const [despacho, setDespacho] = useState([]);
  const [dataDespacho, setDetalleDespacho] = useState([]);

  const [cliente, setCliente] = useState([]);
  const [negociacionCliente, setNegociacionCliente] = useState([]);
  const [negociacionPedido, setNegociacionPedido] = useState([]);

  const id = useParams();

  const consultarDetalleDespacho = () => {
    let payload = {
      id_despacho: id.id
    }
    axiosClient.post('/detalle-despacho', payload)
    .then(({data})=>{
      setDetalleDespacho(data[0].fecha)
      setCliente(data[0].razon_social+" - "+data[0].nombre);
      setNegociacionCliente(data[0].negociacionCliente)
      setNegociacionPedido(data[0].negociacionPedido)
    })
  }

  const consultarDespacho = () => {
    let payload = {
      id_despacho: id.id
    }
    axiosClient.post('/consultar-despacho-detalle', payload)
    .then(({data})=>{
      setDespacho(data)
    })
  }

  const verNotas = (id_registro) => {
    let payload = {
      id_registro
    }
    localStorage.setItem('id_pedido', id_registro);
    axiosClient.post('/notas-pedido-item', payload)
    .then(({data})=> {
      setNotas(data)
    })
  }

  const onSubmitNota = (data) => {
    let payload = {
      id_user: localStorage.getItem("ID_USER"),
      id_pedido_item: localStorage.getItem("id_pedido"),
      nota: notaRef.current.value
    }
    axiosClient.post("/agregar-nota-pedido-item", payload)
      .then(({ data_ }) => {
        window.location = '/despachos-ver';
    })
    .catch((err) => {
      const response = err.response;
      console.log(response);
    });
  }

  useEffect(() => {
    consultarDespacho();
    consultarDetalleDespacho();
  }, [])

  return (
    <main>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1>Despacho # {id.id} del {dataDespacho}</h1>
              <div className="">
                <strong>Cliente: </strong>{cliente}<br></br>
                <strong>Negociación del cliente: </strong>{negociacionCliente}<br></br>
                <strong>Negociación del pedido: </strong>{negociacionPedido}<br></br><br></br>
              </div>
            </div>
            <div className="separator" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
            <table id="datatableRow" className="data-table responsive nowrap cont_tabla_export" data-order="[[ 1, &quot;desc&quot; ]]">
              <thead>
                <tr>
                  <th>Pedido</th>
                  <th>Producto</th>
                  <th>Despachados</th>
                  <th />
                  <th>Notas</th>
                </tr>
              </thead>
              <tbody>
                {despacho.map((registro, i) => (
                <tr key={i}>
                  <td>
                    <p className="list-item-heading">{registro.id_despachos}</p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.codigo}
                      <br /><span className="text-muted">{registro.titulo}</span></p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.despachado}</p>
                  </td>
                  <td>
                  </td>
                  <td><button type="button" className="btn btn-outline-primary" data-toggle="modal" data-target="#exampleModal" onClick={() => verNotas(registro.id)}>
                    Notas
                  </button>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Notas:
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="card-body">
                <h5 className="card-title" />
                <div>
                {notas.map((value, key) => (
                  <div key={key} className="d-flex flex-row mb-3 border-bottom justify-content-between">
                    <a>
                        <img
                           src={value.users.image}
                          alt={value.users.name}
                          className="img-thumbnail border-0 rounded-circle list-thumbnail align-self-center xsmall"
                        />
                      </a>
                      <div className="pl-3 flex-grow-1">
                        <p className="list-item-heading mb-0 ">
                          {value.users.name} &nbsp;
                          <span className="text-muted mb-0 text-small">
                            {value.fecha}
                            <i className="simple-icon-clock mr-1" />
                            {value.hora}
                          </span>{" "}
                        </p>
                        <p className="mt-3">
                          <i className="simple-icon-bubble mr-1 heading-icon" />
                          {value.nota}
                        </p>
                      </div>
                  </div>
                  ))}
                  <form onSubmit={handleSubmit(onSubmitNota)}>
                    <div className="comment-contaiener">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Agregar Nota"
                          ref={notaRef}
                        />
                        <div className="input-group-append">
                          <button className="btn btn-secondary" type="submit">
                            <span className="d-inline-block">Guardar</span>
                            <i className="simple-icon-arrow-right ml-2" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                  
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
      
    </main>
  )
}

export default Despachados;