import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../componentes/Header.jsx";
import Menu from "../componentes/Menu.jsx";
import Footer from "../componentes/Footer.jsx";
import axiosClient from "../axios-client.js";
import { useEffect } from "react";

export default function General(props) {



  const token = localStorage.getItem('ACCESS_TOKEN');

  if(!token)
  {
    window.location.href = "/login";
  }

  const validateTimeAuth = () => {
    let payload = {
      time: localStorage.getItem('TIME_AUTH')
    }
    axiosClient.post('/validate-time-auth', payload)
    .then(({ data }) => {
      if(data.status=='logout'){
        window.location = "/logout";
      }
    })
  }

  useEffect(() => {
    validateTimeAuth()
  }, [])
  return (
    <div>
        <Header />
        <Menu permisosAsignados={props.permisosAsignados}/>
        <Outlet />
        <Footer />
    </div>
  );
}
