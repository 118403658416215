import React, { useEffect, useState, createRef } from 'react'
import { useParams } from 'react-router-dom';
import axiosClient from '../../axios-client';
import { useForm } from "react-hook-form";
import $ from 'jquery';
import Swal from 'sweetalert2';

function EditarDespacho(props) {

  let permisos = props.permisosAsignados;
  if(!permisos.includes('despachos_edit') && permisos!='-1'){
    window.location = '/home';
  }

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [pedidos, setPedidos] = useState([]);
  const [itemPedido, setItemPedido] = useState([]);

  const id = useParams();

  const consultarPedidos = () => {
    let payload = {
        id_despacho: id.id,
    }
    axiosClient.post('/consultar-despacho-detalle', payload)
    .then(({data})=> {
      setPedidos(data)
      consultarPedido();
    })
  }

  const consultarPedido = () => {
    let payload = {
      id_despacho: id.id
    }
    axiosClient.post('/consultar-item-pedido-despacho', payload)
    .then(({data}) => {
      setItemPedido(data);
    })
  }

  const guardarDespacho = () => {
    let data = pedidos;
    for(let i in data){
      data[i]['despachar'] = $("#"+data[i].id).val();
    }
    let payload = {
      id_user: localStorage.getItem("ID_USER"),
      productos: data
    }
    axiosClient.post('/editar-despacho', payload)
    .then(({data_}) => {
      //agregar nuevos items al despacho
      let data__ = itemPedido;
      for(let i in data__){
        data__[i]['despachar'] = $("#new"+data__[i].id).val();
        console.log($("#"+data__[i].id).val())
        if($(".archivar_"+data__[i].id).prop('checked')){
          data__[i]['archivar'] = 'si';
        }else{
          data__[i]['archivar'] = 'no';
        }
      }
      let payloadEdit = {
        id_user: localStorage.getItem("ID_USER"),
        id_despacho: id.id,
        productos: data__
      }
      
      axiosClient.post('/actualizar-despacho', payloadEdit)
      .then(({data_}) => {
        for(let j in data__){
          if(data__[j].archivar=='si'){
            actualizarEstado(data__[j].id)
          }
        }
        window.location = '/despachos-ver';
      })
    })
      
  }

  const actualizarEstado = (id_registro) => {
    let payload = {
      id_registro,
      estado: '4',
      tabla: 'pedidos_item',
      id_pedido: id.id
    }
    axiosClient.post("/enviar-estado-tabla", payload);
  };

  const eliminarItemDespacho = (id_registro) => {
    Swal.fire({ 
        text: "Confirmar eliminación de item", 
        icon: "info", 
        showCancelButton: !0, 
        confirmButtonText: "Enviar", 
        confirmButtonColor: '#86E736', 
        cancelButtonText: "Cancelar",
    }).then((e) => {
        if(e.isConfirmed){
            let payload = {
                tabla: 'despachos_item',
                id_registro,
                estado: '2'
            }
            axiosClient.post('/enviar-estado-tabla', payload)
            .then(({data})=> {
                window.location = '/editar-despacho/'+id.id;
            })
        }
    });
  }

  useEffect(() => {
    consultarPedidos();
  },[])

  return (
    <main>
      <div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1>Editar despacho</h1>
              <h3>Item agregados al despacho</h3>
            </div>
            <div className="separator" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
            <table id="datatableRow" className="data-table responsive nowrap cont_tabla" data-order="[[ 1, &quot;desc&quot; ]]">
              <thead>
                <tr>
                  <th>ID Item</th>
                  <th>Producto</th>
                  <th>Despachado</th>
                  <th>Nueva cantidad</th>
                  <th>Eliminar</th>
                </tr>
              </thead>
              <tbody>
                {pedidos.map((registro, i) => (
                <tr key={i}>
                  <td>
                    <p className="list-item-heading">{registro.id}</p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.codigo}
                      <br /><span className="text-muted">{registro.titulo}</span></p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.despachado}</p>
                  </td>
                  <td>
                    <input type="text" className="form-control" id={registro.id} />
                  </td>
                  <td>
                    <center>
                        <button type="button" onClick={() => eliminarItemDespacho(registro.id)} className="btn btn-danger">Eliminar</button>
                    </center>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h1>Items del pedido que no se agregaron al despacho</h1>
            </div>
            <div className="separator" />
          </div>
        </div>
        <div className="row">
          <div className="col-12 mb-4 data-table-rows data-tables-hide-filter">
            <table id="datatableRow" className="data-table responsive nowrap cont_tabla" data-order="[[ 1, &quot;desc&quot; ]]">
              <thead>
                <tr>
                  <th>ID Item</th>
                  <th>Producto</th>
                  <th>Cantidad</th>
                  <th>Stock</th>
                  <th>Despachar</th>
                  <th><center>Archivar</center></th>
                </tr>
              </thead>
              <tbody>
                {itemPedido.map((registro, i) => (
                <tr key={i}>
                  <td>
                    <p className="list-item-heading">{registro.id}</p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.codigo}
                      <br /><span className="text-muted">{registro.titulo}</span></p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.cantidad}</p>
                  </td>
                  <td>
                    <p className="list-item-heading">{registro.stock}</p>
                  </td>
                  <td>
                    <input type="text" className="form-control" id={"new"+registro.id} />
                  </td>
                  <td>
                    <center>
                      <input type="checkbox" className={"archivar_"+registro.id}/>
                    </center>
                  </td>
                </tr>
                ))}
              </tbody>
            </table>
            <a onClick={() => guardarDespacho()}><button type="button" className="btn btn-primary btn-block mb-1">Guardar</button></a>
          </div>
        </div>
      </div>

    </main>
  )
}

export default EditarDespacho;